<script setup>
import { ref, watchEffect } from 'vue'
import router from './router'
import * as web3 from '@solana/web3.js'
import store from '@/store'
import Header from '@/components/Header.vue'
import { PhantomWalletAdapter, SolflareWalletAdapter, LedgerWalletAdapter } from '@solana/wallet-adapter-wallets'
import {
  resolveToWalletAddress  //, getParsedNftAccountsByOwner,
} from "@nfteyez/sol-rayz";
import { initWallet, useWallet } from 'solana-wallets-vue'
const carousel = ref(false)
const slide = ref(1)
const wallets = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter(),
  new LedgerWalletAdapter()
]
initWallet({ wallets, autoConnect: false })
const wallet = useWallet()
const isAdmin = ref(false)
const id = ref('')
watchEffect(async () => {
  if (wallet.connected.value) {
    const rawId = new web3.PublicKey(wallet.publicKey.value);
    id.value = await resolveToWalletAddress({ text: rawId.toBase58() })

    store.dispatch('checkAdmin', id.value)
    isAdmin.value = store.state.isAdmin
  }
  else {
    isAdmin.value = false
    if (window.location.pathname !== '/') {
      router.push('/')
    }
  }
})

</script>
<template>
  <q-layout view="hHh ppp fFf">
    <Header />
    <q-page-container class="wrapper">
      <div class="btn-container">
        <q-btn v-if="!carousel" id="mintInfoBtn" @click="carousel = true" label="Phase 1 Mint Info" dark />
      </div>
      <q-dialog v-model="carousel">
        <q-carousel transition-prev="slide-right" transition-next="slide-left" swipeable animated v-model="slide"
          control-color="white" navigation-icon="radio_button_unchecked" navigation padding height="300px"
          class="carousel shadow-10 rounded-borders" dark>
          <q-carousel-slide :name="1" class="column no-wrap flex-center">
            <q-icon name="label_important" color="white" size="56px" />
            <div class="q-mt-md text-center">
              <h5 class="carousel-key">Collection</h5>
              <p class="carousel-content">Founders</p>
            </div>
          </q-carousel-slide>
          <q-carousel-slide :name="2" class="column no-wrap flex-center">
            <q-icon name="event" color="white" size="56px" />
            <div class="q-mt-md text-center">
              <h5 class="carousel-key">Mint Date</h5>
              <p class="carousel-content">TBA</p>
            </div>
          </q-carousel-slide>
          <q-carousel-slide :name="3" class="column no-wrap flex-center">
            <q-icon name="pie_chart_outline" color="white" size="56px" />
            <q-list>
              <q-list-item>
                <p class="carousel-key">Collection Size</p>
                <p class="carousel-content text-right">2552</p>
              </q-list-item>
              <q-list-item>
                <p class="carousel-key">Whitelist</p>
                <p class="carousel-content text-right">1000</p>
              </q-list-item>
            </q-list>
          </q-carousel-slide>
          <q-carousel-slide :name="4" class="column no-wrap flex-center">
            <q-icon name="payments" color="white" size="56px" />
            <div class="q-mt-md text-center">
              <h5 class="carousel-key">Mint Cost</h5>
              <p class="carousel-content text-right">TBA</p>
            </div>
          </q-carousel-slide>
          <q-carousel-slide :name="5" class="column no-wrap flex-center">
            <q-icon name="info" color="white" size="56px" />
            <h5 class="carousel-key">Founder Utility</h5>
            <q-btn class="glowing-btn" @click="() => carousel = !carousel" flat dark label="Let's Go!" to="/utility" />
          </q-carousel-slide>
        </q-carousel>
      </q-dialog>
      <router-view v-if="!carousel"></router-view>
    </q-page-container>
  </q-layout>
</template>
<style scss>
.wrapper {
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  font-family: "Orbitron", sans-serif;
  min-height: 100vh;
}

.facet .card h3 {
  font-weight: 900;
}

.facet .card .image {
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}

.facet .card {
  width: 90%;
  margin: 7rem auto;
  flex: 0 0 90%;
  min-height: 50vh;
  box-shadow: 0px 1px 11px 2px rgba(255, 255, 255, 0.51);
  transition: all 0.5s ease-in-out;
}

.facet .card:hover {
  box-shadow: 0px 1px 15px 5px rgba(255, 255, 255, 0.51);
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

#mintInfoBtn {
  min-width: 300px;
  min-height: 80px;
  position: fixed;
  bottom: 1rem;
  left: 50;
  color: rgba(255, 255, 255, .95);
  z-index: 999;
  font-weight: 800;
  font-size: 1.25rem;
  margin: 0.5rem auto 0;
  background-color: rgba(204, 0, 255, 0.75);
  box-shadow: 0 2px 4px -1px rgb(255 255 255 / 20%),
    0 4px 10px rgb(255 255 255 / 14%), 0 1px 20px rgb(255 255 255 / 12%) !important;
  -webkit-animation: pulse 1.5s infinite ease-in-out;
  -moz-animation: pulse 1.5s infinite;
  -o-animation: pulse 1.5s infinite;
  animation: pulse 1.5s infinite ease-in-out;
}

#mintInfoBtn:hover {
  -webkit-animation: none;

}

@-webkit-keyframes pulse {
  0% {
    transform: scale(0.75);
  }

  /* 30%{
    transform: scale(.8);
  }
  50%{
    transform: scale(1);
  } */
  70% {
    transform: scale(0.8);
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}

.carousel {
  width: 60%;
  box-shadow: 0 2px 4px -1px rgb(255 255 255 / 20%),
    0 4px 5px rgb(255 255 255 / 14%), 0 1px 10px rgb(255 255 255 / 12%) !important;
}

.carousel-content {
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.carousel-key {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: normal;
  margin: 0 auto;
  font-weight: 900;
}

.carousel-stack {
  width: 100%;
  display: inline-flex;
  justify-content: space-evenly;
}

.glowing-btn {
  min-width: 50%;
  font-size: 1.25rem !important;
  margin: 1.15rem auto;
  -webkit-animation: glowing 2500ms infinite;
  -moz-animation: glowing 2500ms infinite;
  -o-animation: glowing 2500ms infinite;
  animation: glowing 2500ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: var(--primary-background);
    -webkit-box-shadow: 0 0 2px #cacaca;
  }

  50% {
    background-color: var(--primary-background);
    -webkit-box-shadow: 0 0 6px #ffffff;
  }

  100% {
    background-color: var(--primary-background);
    -webkit-box-shadow: 0 0 1px #cacaca;
  }
}

@media screen and (max-width: 467px) {
  .facet .card {
    width: 98%;
  }

  .facet .card h3 {
    text-align: center;
    font-size: 2.25rem;
  }
}
</style>