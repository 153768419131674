<script setup>
</script>
<template>
          <q-card class="card" dark>
            <q-card-section class="image">
                <!-- <q-img src="../assets/logo.png" alt="Low Poly Phoenix" /> -->
                <h3>Saved By Fire</h3>
            </q-card-section>
            <q-separator dark spaced />
            <q-card-section class="content">
                <p>Comprised of the holders of Phoenix Founder or Member NFTs; the mission of the DAO is simple – utilize the collective funding as a revenue generation vehicle.</p>
                <q-space />
                <p>Guided by the Founders and the Prjct Phoenix core team, votes will be held frequently as to how to best use the assets being held by to benefit the DAO.</p>
                <q-space />
                <p>One of the first priorities after mint is the finalization and deployment of the DAO dashboard, which will be home to votes, proposal drafting , and a single point to view DAO assets.</p>
                <q-space />
            </q-card-section>
        </q-card>
</template>
<style scoped>

.image{
    background-image: url("../assets/logo.png");
    background-position:center;   
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 300px;
}

.card {
    /* flex: 0 0 60%; */
}

.content {
    padding: 2.5rem;
    font-size: 1.25rem;
}

@media screen and (max-width: 476px) {
    .image{
        max-height:200px;
        min-height: unset;
    }
    
}
</style>