<script setup>
</script>
<template>
        <q-card class="card" dark>
            <q-card-section class="image">
                <!-- <q-img src="../assets/logo.png" alt="Low Poly Phoenix" /> -->
                 <h3>Ardent Nest</h3>
            </q-card-section>
            <q-separator dark spaced />
            <q-card-section class="content">
                <p>The Phoenix incubator. Creators and developers who have pieces of what it takes to create their vision, are invited to apply for assistance in any area of building out their idea.</p>
                <q-space />
                <p>On a case by case basis, Ardent Nest will provide assistance through our network of skills. </p>
                <q-space />
                <p>In exchange for our services, all projects will be required to provide something to benefit the Phoenix DAO members directly.</p>
                <q-space />
                <sub>Costs of services vary</sub>
            </q-card-section>
        </q-card>
</template>
<style scoped>
.image{
    background-image: url("../assets/logo.png");
    background-position:center;   
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 300px;
}

.content {
    padding: 2.5rem;
    font-size: 1.25rem;
}

@media screen and (max-width: 476px) {
    .image{
        max-height:200px;
        min-height: unset;
    }
    
}
</style>