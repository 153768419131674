<script setup>
import {ref, watchEffect} from 'vue'
import * as web3 from '@solana/web3.js'
import {
  resolveToWalletAddress  //, getParsedNftAccountsByOwner,
} from "@nfteyez/sol-rayz";
import { WalletMultiButton, useWallet } from 'solana-wallets-vue'
import store from '@/store'
const wallet = useWallet()
const id = ref()
const isAdmin = ref(false)
watchEffect(async ()=> {
  if(wallet.connected.value){
    const rawId =  new web3.PublicKey(wallet.publicKey.value);
    //console.log(rawId)
    id.value = await resolveToWalletAddress({text: rawId.toBase58()})
    //console.log(id.value)
store.dispatch('checkAdmin', id.value)
isAdmin.value = store.state.isAdmin
//console.log({'isAdmin Header':isAdmin.value})
  }
  if(!wallet.connected.value){
    isAdmin.value = false;
  store.dispatch('checkAdmin', )
  }
isAdmin.value = store.state.isAdmin
//console.log({isAdmin: isAdmin.value})


})
//console.log(window.location.hash)
</script> 
<template>
  <q-header flat class="header">
    <q-toolbar>
      <q-toolbar-title class="title">Prjct Phoenix</q-toolbar-title>
    <q-space/>
      <wallet-multi-button></wallet-multi-button>
    </q-toolbar>
    <q-tabs class="link-tabs" align="center">
      <q-route-tab id="home" to="/" label="Home" />
      <!-- <q-route-tab id="affiliate" to="/affiliate" label="Affiliate Oportunities"/> -->
      <q-route-tab id="about" to="/concept" label="Concept" />
      <q-route-tab id="roadmap" to="/roadmap" label="Roadmap" />
      <q-route-tab id="connect" to="/connect" label="Join Us"/>
      <q-route-tab v-if="isAdmin" id="admin" to="/admin" label="Admin Info"/>
      <q-route-tab id="utility" to="/utility" label="Utility"/>
      <q-route-tab id="mint" disabled ="mint" to="/mint" label="Mint" />
    </q-tabs>
  </q-header>
</template>
  
<style scss scoped>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.header {
  color: #ffff;
  font-family: "Orbitron", "Helvetica", sans-serif;

  position: fixed;
  top:0;
  background-color: rgba(0, 0, 0, 0.1);
  background-color: transparent;
  /* background-image: url("../assets/fire.png"); */
  background-position: center;
}
.title{
  font-size:calc(11px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-variant: small-caps;
  color:#ffffff;
    font-weight:900;
}
.link-tabs {
  font-weight: bold;
}
.link-tabs a {
  background-color: rgba(0, 0, 0, 0.8);
}
#home{
  border-top-left-radius: 15px;
}
#mint{
  border-top-right-radius: 15px;
}

@media screen and (max-width:550px){

}
</style>