import { createStore } from 'vuex'

export default createStore({
    state: {
        carousel: false,
        pubkey: '',
        isAdmin: false
    },
    mutations: {
        setAdmin(state, value){
            state.isAdmin = value
        }
    },
    actions: {
        checkAdmin( context, value){
            // console.log({ value })
            let adminIds = [
                '3dJKKSazHSZw6GwBEX9cXcGZgC4P6oUqi6fbBgM3N2qL',
                'GaFv1cDrrbfp9jtGGcei65B2iGeT81mN8HAt3PVL6T3m',
                // Slade 
                 '36H8PJMKK1bpmGuG9Vk6MHzXX9LaWur5uDka3B72iHxM',
                '93z6SFyPU91sPEbRaPPcE8W8nDSeLCNPcvkn8mTV8XY1',
                // Blanc
                'Hive2wTMoC1xQuc1EteY9hUGqpGZKG6Fd1Zs5XmxAKcU',
                // Ares
                'iJPTeLSwa677Gy1c7GzSC1QkvbBoKX97gYxVipt3nFU',
                // Flame
                'DTiYdpafXbCyUPef7ryZEH8QnpScbBbEYPYtcZMvEVER',
                // PrjctPhoenix Wallet
                'BhsVi2SQBvvddpxEYWt3Xnhu4udeeKwkS7uHGTDPhSmn']
            if (adminIds.includes(value)){
                context.commit('setAdmin', true)
            } else {
                context.commit('setAdmin', false)
            }
        }
    },
    modules: {}
})