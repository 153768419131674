<script setup>
</script>
<template>
        <q-card class="card" dark>
            <q-card-section class="image">
                <!-- <q-img src="../assets/FoF_logo.png" alt="Origami Birds" /> -->
                <h3>Flock of Flames</h3>
            </q-card-section>
            <q-separator dark spaced/>
            <q-card-section class="content">
                <p>Flock of Flames is the general term revering to any web application, service, or tool offered by Prjct Phoenix.</p>
                <q-space />
                <p>These will be developed in different “flocks” depending on their intended use case, audience, and maintenance once deployed.</p>
                <q-space />
                <p>The first thing being developed will be the DAO dashboard, which will be a unique tool provided exclusively to our Founder and Member holders.</p>
            </q-card-section>
        </q-card>
</template>
<style scoped>
.container {
    height: 100%;
    /* margin:25% auto;
    transform: translateY(-25%); */
    padding: 1rem 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.image{
    background-image: url("../assets/FoF_logo.png");
    background-position:center;   
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 300px;
}

.content{
    padding: 2.5rem;
    font-size: 1.25rem;
}
@media screen and (max-width: 476px) {
    .image{
        max-height:200px;
        min-height: unset;
    }
    
}
</style>