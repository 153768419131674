import {
    createRouter,
    // createWebHistory,
    createWebHashHistory
} from 'vue-router'
// import store from '@/store'
import Home from '../views/Home.vue'
// import Utility from '@/views/Utility.vue'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/concept',
    name: 'Concept',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Concept.vue')
}, {
    path: '/roadmap',
    name: 'Roadmap',
    component: () =>
        import( /* webpackChunkName: "roadmap" */ '../views/Roadmap.vue')
}, {
    path: '/utility',
    name: 'Utility',
    // component: Utility
    component: () => import('../views/Utility.vue')
},
{
    path: '/connect',
    name: 'Connect',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Connect.vue')
},
{
    path: '/admin',
    name: 'Admin Info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Admin.vue'),

},
// {
//     path: '/mint',
//     name: 'Mint',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//         import( /* webpackChunkName: "about" */ '../views/Mint.vue')
// },
{
    path: '/affiliate',
    name: 'Affiliate',
    component: () => import('../views/Affiliate.vue')
}
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        const { hash } = to
        if (hash) {
            return {
                el: hash
            }
        }
        if (savedPosition) {
            return savedPosition
        }
        return
    },
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router